import TransactionService from './transaction.service'
import Axios from 'axios'

export default {
  list_rooms(config, bldg) {
    const ta = [
      {name: 'nd.bldg.list', old: {number: bldg}}, // Buildings
      {
        name: 'nd.room.list',
        inner_join_ref: {bldg_list: 'default'}
      }, // Rooms
      {name: 'nd.floor.list', inner_join_ref: {room_list: 'default'}} // Floors
    ]
    return TransactionService.execute(config, ta)
  },
  list_modules(config, bldg, room) {
    const ta = [
      {
        idx: 'tmp_module_list',
        name: 'nd.module.list',
        old: {bldg: bldg, room: room}
      },
      {
        idx: 'tmp_module_type_list',
        name: 'ndcfg.module_type.list',
        inner_join_ref: {
          tmp_module_list: 'default'
        }
      },
      {idx: 't1', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'MK'}}, // Module type classes
      {idx: 't2', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'PS'}}, // Module type classes
      {idx: 't3', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'Chs'}}, // Module type classes
      {idx: 't4', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'AP'}}, // Module type classes
      {idx: 't5', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'FuAP'}}, // Module type classes
      // {idx: 't6', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'BlkBx'}}, // Module type classes
      {idx: 't7', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'StatCblLWL'}}, // Module type classes
      {idx: 't8', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'PatchCblCU'}}, // Module type classes
      {idx: 't9', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'FuCo'}}, // Module type classes
      {idx: 't10', inner_join_ref: {tmp_module_type_list: 'default'}, name: 'ndcfg.module_type_class.list', old: {name: 'StatCblCU'}}, // Module type classes
      {
        name: 'ndcfg.module_type.list',
        anti_join_ref: {
          t1: 'default',
          t2: 'default',
          t3: 'default',
          t4: 'default',
          t5: 'default',
          // t6: 'default', tmp: bis port2ou existiert muss das hier raus
          t7: 'default',
          t8: 'default',
          t9: 'default',
          t10: 'default',
        },
        inner_join_ref: {tmp_module_type_list: 'self'}
      }, // Module types
      {
        name: 'nd.module.list',
        old: {bldg: bldg, room: room},
        inner_join_ref: {module_type_list: 'default'}
      }, // Modules
      {idx: 'local_cbl_p_ports', name: 'nd.p_port.list', inner_join_ref: {module_list: 'default'}, old: {is_edge_node: false}},
      {idx: 'remote_cbl_p_ports', name: 'nd.p_port.list', inner_join_ref: {local_cbl_p_ports: 'api_fkey_nd_p_port_conn'}},
      {idx: 'remote_end_p_ports', name: 'nd.p_port.list', inner_join_ref: {remote_cbl_p_ports: 'api_fkey_nd_p_port_conn_int'}},
      {idx: 'remote_end_p_ports2mdl_type', name: 'ndcfg.p_port2module_type.list', inner_join_ref: {remote_end_p_ports: 'default'}},
      // in(sert)ception
      {idx: 'module_type_list_remotes', name: 'ndcfg.module_type.list', inner_join_ref: {remote_end_p_ports2mdl_type: 'default'}},
      {name: 'cntl.ot_attr_val.list', idx: 'ot_attr_val_list', inner_join_ref: {module_type_list_remotes: null}, old: {ot_attr_def_key_word: 'prq_insert_is_exchangeable', value: true, value_operator: 'eq'}},
      {name: 'ndcfg.module_type.list', idx: 'module_type_list_changeable', inner_join_ref: { module_type_list_remotes: 'self', ot_attr_val_list: null}},
      {idx: 'p2m_type_list', inner_join_ref: {remote_end_p_ports2mdl_type: 'self', module_type_list_changeable: 'default'}, name: 'ndcfg.p_port2module_type.list'},
      {
        idx: 'insert_changeable_remote_end_p_ports',
        name: 'nd.p_port.list',
        inner_join_ref: {remote_end_p_ports: 'self', p2m_type_list: 'default'},
      },
      {idx: 'insert_changeable_remote_cbl_p_ports', name: 'nd.p_port.list', inner_join_ref: {insert_changeable_remote_end_p_ports: 'api_fkey_nd_p_port_conn_int'}},
      {idx: 'insert_changeable_local_cbl_p_ports', name: 'nd.p_port.list', inner_join_ref: {insert_changeable_remote_cbl_p_ports: 'api_fkey_nd_p_port_conn'}},
      {idx: 'insert_changeable_modules', name: 'nd.module.list', inner_join_ref: {module_list: 'self', insert_changeable_local_cbl_p_ports: 'default'}},
      {idx: 'tmp_p_port_list', name: 'nd.p_port.list', inner_join_ref: {module_list: 'default'}}, // Join PPorts
      {idx: 'u1', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'AC/220V'}}, // p_port type groups
      {idx: 'u2', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'Antennen/gerichtet'}},
      {idx: 'u3', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'Antennen/ungerichtet'}},
      {idx: 'u4', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'Cisco-GS'}},
      {idx: 'u5', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'DC/12V'}},
      {idx: 'u6', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'DC/48V'}},
      {idx: 'u7', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'Laser'}},
      {idx: 'u8', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'Pseudo'}},
      {idx: 'u9', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'TELCO'}},
      {idx: 'u10', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'Backplane'}},
      {idx: 'u11', inner_join_ref: {tmp_p_port_list: 'self'}, name: 'nd.p_port.list', old: {type_group: 'PWR'}},
      {
        name: 'nd.p_port.list',
        inner_join_ref: {tmp_p_port_list: 'self'},
        anti_join_ref: {
          u1: 'self',
          u2: 'self',
          u3: 'self',
          u4: 'self',
          u5: 'self',
          u6: 'self',
          u7: 'self',
          u8: 'self',
          u9: 'self',
          u10: 'self',
          u11: 'self',
        },
      }, // Join PPorts
      {name: 'ndcfg.p_port2module_type.list', inner_join_ref: {p_port_list: 'default'}},
      {name: 'ndcfg.p_port_type_data.list', inner_join_ref: {p_port2module_type_list: 'default'}},
      {name: 'ndcfg.p_port_type.list', inner_join_ref: {p_port_type_data_list: 'default'}},
    ]
    return TransactionService.execute(config, ta)
  },
  list_bcds(config) {
    const ta = [
      {name: 'nd.bcd.list', old: {is_own: true, sorting_params_list: ['name']}}
    ]
    return TransactionService.execute(config, ta)
  },
  list_sites(config) {
    return Axios.get('/api/patch_request/sites', ('netdb_axios_config' in (config || {})) ? config.netdb_axios_config : config)
  },
  list_insert_types(config) {
    return Axios.get('/api/patch_request/insert_types', ('netdb_axios_config' in (config || {})) ? config.netdb_axios_config : config)
  },
  send_patch_request(config, actions, reply_to) {
    const slimmed_actions = []
    for (const action of actions) {
      const new_insert_assignment = []
      console.log(action.selected_insert_bcds)
      for (const port in action.selected_insert_bcds || []) {
        new_insert_assignment.push({
          port_name: port,
          bcd_name: action.selected_insert_bcds[port].bcd.name,
          bcd_tagged: action.selected_insert_bcds[port].is_tagged
        })
      }
      slimmed_actions.push({
        building_gpk: action.building.gpk,
        room_gpk: action.room.gpk,
        module_gpk: action.module.gpk,
        action: action.action,
        p_port_gpk: action.p_port ? action.p_port.gpk : null,
        bcd_name: action.bcd ? action.bcd.name : null,
        bcd_tagged: action.bcd_tagged ? action.bcd_tagged : null,
        use_lacp: action.use_lacp ? action.use_lacp : null,
        comment: action.comment,
        new_insert_type: action.selected_insert_type ? action.selected_insert_type : null,
        new_insert_assignment: new_insert_assignment
      })
    }
    return Axios.post('/api/patch_request', {
      actions: slimmed_actions,
      reply_to: reply_to
    }, ('netdb_axios_config' in (config || {})) ? config.netdb_axios_config : config)
  },
  send_informal_patch_request(config, data) {
    return Axios.post('/api/patch_request/informal', data, ('netdb_axios_config' in (config || {})) ? config.netdb_axios_config : config)
  }
}
